import { PaletteColorOptions, PaletteOptions } from '@mui/material';
import { CustomPaletteOptions } from '@mui/material/styles';

export enum Color {
  /** Primary Pallet */
  // Base Colors
  BaseBlack = '#000000',
  BaseGray = '#F8F8F8',
  BaseWhite = '#FFFFFF',
  // BaseBlue = '#3D53F5',
  // BasePurple = '#563FD8',
  // BaseLightBlue = '#C7E8F3',

  // Dark Amethyst
  Amethyst100 = '#26225C',
  // Amethyst200 = '#36327D',
  // Amethyst300 = '#4E4AA0',
  // Amethyst400 = '#635EB6',
  // Amethyst500 = '#716CC3',
  // Amethyst600 = '#7C78D0',

  // Slate
  Slate50 = '#17171D',
  Slate100 = '#343441',
  Slate200 = '#717182',
  Slate300 = '#9292A6',
  Slate400 = '#CBCBDA',
  Slate500 = '#EDEDF3',
  Slate600 = '#F6F6F9',

  // Alert Colors
  // SuccessGreen = '#2E7D32',
  // WarningYellow = '#FFBB38',
  // ErrorRed = '#DF1642',

  /** Secondary Pallet */
  // Gray Swatch
  Gray100 = '#F5F5F5',
  Gray200 = '#EEEEEE',
  Gray300 = '#E0E0E0',
  Gray400 = '#BDBDBD',

  // Warm Lilac
  Lilac50 = '#3D3783',
  Lilac100 = '#4F4998',
  Lilac200 = '#7670C8',
  Lilac300 = '#B7B7F5',
  Lilac400 = '#D9D9FF',
  Lilac500 = '#ECECFF',
  Lilac600 = '#F5F5FE',

  // Plum
  Plum100 = '#784982',
  Plum200 = '#91629B',

  // Sea Green
  SeaGreen100 = '#067F7F',
  SeaGreen200 = '#479EA2',
  SeaGreen600 = '#EEF6F6',

  /** Extended Pallet */
  // Bubblegum100 = '#FF7385',
  // Bubblegum200 = '#F4B7BF',

  Apricot100 = '#F7830F',
  Apricot200 = '#FF9800',
  Apricot600 = '#FEF3E7',

  Raspberry50 = '#C81761',
  Raspberry100 = '#D82C74',
  Raspberry200 = '#DA568D',
  Raspberry600 = '#FCF0F5',
}

declare module '@mui/material/styles' {
  interface CustomPaletteOptions extends PaletteOptions {
    neutral: PaletteColorOptions;
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    neutral: true;
  }
}

export const colorPaletteThemeOptions: CustomPaletteOptions = {
  mode: 'light',
  common: {
    black: Color.Slate100,
    white: Color.BaseWhite,
  },
  primary: {
    main: Color.Lilac100,
    contrastText: Color.BaseWhite,
    dark: Color.Amethyst100,
    light: Color.Lilac200,
  },
  secondary: {
    main: Color.Plum100,
    contrastText: Color.BaseWhite,
    dark: Color.Plum100,
    light: Color.Plum200,
  },
  neutral: {
    main: Color.Slate500,
    contrastText: Color.Slate100,
    dark: Color.Slate500,
    light: Color.Slate500,
  },
  error: {
    main: Color.Raspberry100,
    contrastText: Color.BaseWhite,
    dark: Color.Raspberry100,
    light: Color.Raspberry200,
  },
  warning: {
    main: Color.Apricot100,
    contrastText: Color.Slate100,
    dark: Color.Apricot100,
    light: Color.Apricot200,
  },
  info: {
    main: Color.Slate500,
    contrastText: Color.BaseWhite,
    dark: Color.Slate400,
    light: Color.Slate500,
  },
  success: {
    main: Color.SeaGreen100,
    contrastText: Color.BaseWhite,
    dark: Color.SeaGreen100,
    light: Color.SeaGreen200,
  },
  grey: {
    100: Color.Gray100,
    200: Color.Gray200,
    300: Color.Gray300,
    400: Color.Gray400,
  },
  text: {
    primary: Color.Slate100,
    secondary: Color.Slate200,
    disabled: Color.Slate400,
  },
  background: {
    default: Color.BaseWhite,
    paper: Color.BaseWhite,
  },
};
