import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { PatientDto } from '@dto';
import { PatientAddressDto } from '@dto';
import { Patient } from '@models';

import { environment } from '../../../environments/environment';
import { PatientVitalsDto, PatientVitalsResponseDto } from '@dto';
import { PaginationInformation } from '../../shared/server-side-paginated-table/datasources/generic.datasource';
import { Relationship } from '@enums';
import { PaginatedResponse } from '@models';
import { UserService } from './user.service';
import { UUID } from '../../react/types';

@Injectable({
  providedIn: 'root',
})
export class PatientService {
  public readonly selectedPatient: Observable<Patient>;
  public readonly selectedPatientId: Observable<string>;

  private _selectedPatient = new BehaviorSubject<Patient>(null);
  private _selectedPatientId = new BehaviorSubject<string>(null);

  constructor(private httpClient: HttpClient, private userService: UserService) {
    this.selectedPatient = this._selectedPatient.asObservable();
    this.selectedPatientId = this._selectedPatientId.asObservable();

    this.selectedPatientId
      .pipe(
        switchMap((id) => {
          if (!id) {
            return of(null);
          }
          return this.getPatientDetailed(id);
        })
      )
      .subscribe((result) => this._selectedPatient.next(result));
  }

  public updateSelectedPatientId(id: string) {
    this._selectedPatientId.next(id);
  }

  /**
   * @deprecated Use new APIs
   */
  public searchForPatientByNameOrId(search: string) {
    let queryParams = new HttpParams({
      fromObject: {
        search,
        sortBy: 'firstName',
        sortDirection: 'ASC',
        limit: '10',
        page: '0',
      },
    });

    queryParams = queryParams.append('searchBy[]', 'id');
    queryParams = queryParams.append('searchBy[]', 'name');
    const showDeleted = 'false';
    return this.getLegacyPatientsPaginated(queryParams, {
      filters: { showDeleted },
    } as any);
  }

  /**
   * @deprecated Use new APIs
   */
  public searchForPatientById(search: string) {
    let queryParams = new HttpParams({
      fromObject: {
        search,
        sortBy: 'id',
        sortDirection: 'ASC',
        limit: '10',
        page: '0',
      },
    });

    queryParams = queryParams.append('searchBy[]', 'id');
    const showDeleted = 'false';
    return this.getLegacyPatientsPaginated(queryParams, {
      filters: { showDeleted },
    } as any);
  }

  /**
   * @deprecated Use new APIs
   */
  public searchForPatientByNameOrIdOrDobNav(search: string) {
    let queryParams = new HttpParams({
      fromObject: {
        search,
        sortBy: 'navSearch',
        sortDirection: 'ASC',
        limit: '10',
        page: '0',
      },
    });
    queryParams = queryParams.append('searchBy[]', 'dateOfBirth');
    queryParams = queryParams.append('searchBy[]', 'id');
    queryParams = queryParams.append('searchBy[]', 'name');
    const showDeleted = 'false';
    return this.getLegacyPatientsPaginated(queryParams, {
      filters: { showDeleted },
    } as any).pipe(
      map((res) =>
        res.data.map((patient) => ({
          ...patient,
          label: `#${patient.id} ${patient.firstName} ${patient.lastName}`,
        }))
      )
    );
  }

  /**
   * @deprecated Use new APIs
   */
  public getLegacyPatientsPaginated(queryParams: HttpParams, { filters }: PaginationInformation) {
    const url = environment.apiV3Url.concat('/patients');

    queryParams = queryParams.append('showDeleted', filters.showDeleted as string);

    if (filters.verified) {
      queryParams = queryParams.append('verified', 'false');
    }

    return this.httpClient
      .get<PaginatedResponse<PatientDto>>(url, {
        params: queryParams,
      })
      .pipe(
        map((patients) => ({
          totalCount: patients.totalCount,
          data: patients.data.map((patientData) => {
            const patient = new Patient(patientData);

            const email = patient.email
              ? patient.email
              : (patient.membership &&
                  patient.membership.primaryAccountHolder &&
                  patient.membership.primaryAccountHolder.email) ||
                '';
            const phone = patient.phone
              ? patient.phone
              : (patient.membership &&
                  patient.membership.primaryAccountHolder &&
                  patient.membership.primaryAccountHolder.phone) ||
                '';
            const isPrimaryAccountHolder = patient.relationToAccountHolder === Relationship.Self;

            return {
              email,
              phone,
              isPrimaryAccountHolder,
              id: patient.id,
              firstName: patient.firstName,
              lastName: patient.lastName,
              sexAssignedAtBirth: patient.sexAssignedAtBirth,
              gender: patient.gender,
              dateOfBirth: patient.dateOfBirth,
              membershipId: patient.membership ? patient.membership.id : null,
              membershipTypeName:
                patient.membership && patient.membership.membershipType
                  ? patient.membership.membershipType.membershipName
                  : null,
              employerName:
                (patient.membership && patient.membership.company && patient.membership.company.name) || null,
              address1: patient.addresses && patient.addresses.length ? patient.addresses[0].address1 : null,
              address2: patient.addresses && patient.addresses.length ? patient.addresses[0].address2 : null,
              city: patient.addresses && patient.addresses.length ? patient.addresses[0].city : null,
              zip: patient.addresses && patient.addresses.length ? patient.addresses[0].zip : null,
              state: patient.addresses && patient.addresses.length ? patient.addresses[0].state : null,
              membershipTypeId:
                patient.membership && patient.membership.membershipType ? patient.membership.membershipType.id : null,
              copayAmount:
                (patient.membership &&
                  patient.membership.membershipType &&
                  (patient.membership.membershipType.copayAmount as string)) ||
                null,
              verified: patient.verified,
              active: patient.active,
              deleted: patient.deleted,
              syncStatus: patient.chronoPatientId && !patient.chronoSyncError,
              rawPatient: patient,
              chronoPatientId: patient.chronoChartId,
            };
          }),
        }))
      );
  }

  /**
   * @deprecated Use new APIs
   * Returns patients list with additional details re: their membership
   */
  public getPatientsByMembershipId(id: string | number) {
    const url = environment.apiV3Url.concat(`/memberships/${id}/patients`);
    return this.httpClient
      .get<PatientDto[]>(url)
      .pipe(map((patients) => patients.map((patient) => new Patient(patient))));
  }

  public getPatient(patientId: string | number) {
    const url = environment.apiUrl.concat(`/patient/${patientId}`);

    return this.httpClient.get<PatientDto>(url).pipe(map((patient) => new Patient(patient)));
  }

  public getPatientDetailed(patientId: string): Observable<Patient> {
    const url = environment.apiUrl.concat(`/patient/${patientId}/membershipDetail`);

    return this.httpClient.get<PatientDto>(url).pipe(
      map((patient: PatientDto) => {
        return new Patient(patient);
      })
    );
  }

  /**
   * @deprecated Use new APIs
   */
  public updatePatient(data: Partial<PatientDto>) {
    const patientId = data.id;
    const url = environment.apiUrl.concat(`/patients/${patientId}`);
    return this.httpClient.put(url, data);
  }

  /**
   * @deprecated Use new APIs
   */
  public updatePatientAddress(addressId: string | number, address: PatientAddressDto | Partial<PatientAddressDto>) {
    const url = environment.apiUrl.concat(`/patient-address/${addressId}`);
    return this.httpClient.put(url, address);
  }

  /**
   * @deprecated Use new APIs
   */
  public addPatientAddress(address: PatientAddressDto) {
    const url = environment.apiUrl.concat('/patient-address');
    return this.httpClient.post(url, address);
  }

  public addPatientNote(note: string, patientId: number) {
    const url = environment.apiUrl.concat(`/patient/${patientId}/notes`);
    return this.httpClient.post(url, { note });
  }

  public addPatientVitals(vitals: PatientVitalsDto): Observable<PatientVitalsResponseDto> {
    const url = environment.apiV3Url.concat('/patient-vitals');

    return this.httpClient.post<PatientVitalsDto>(url, vitals);
  }

  public updatePatientVitals(vitals: PatientVitalsDto): Observable<PatientVitalsResponseDto> {
    const url = environment.apiV3Url.concat(`/patient-vitals/${vitals.id}`);

    return this.httpClient.put<PatientVitalsDto>(url, vitals);
  }

  public getPatientVitals(patientId: number): Observable<PatientVitalsResponseDto[]> {
    const url = environment.apiV3Url.concat('/patient-vitals');

    return this.httpClient.get<PatientVitalsResponseDto[]>(url, {
      params: new HttpParams().set('patientId', patientId.toString()),
    });
  }

  /**
   * @deprecated use getElationPatientLink(patientUserId)
   * @param patientId
   */
  public async getElationPatientLinkByPatientId(patientId: number): Promise<PatientElationLinkResponse> {
    const patient = await this.userService.getPatientUser(patientId);

    return this.getElationPatientLink(patient.userId as UUID);
  }

  public async getElationPatientLink(patientUserId: UUID): Promise<PatientElationLinkResponse> {
    const url = environment.niceServiceUrl.concat(`/v1/elation/patients/${patientUserId}/link`);
    return await this.httpClient.get<PatientElationLinkResponse>(url).toPromise();
  }
  public async updatePatientElationId(patientId: number, elationId: string): Promise<any> {
    const patient = await this.userService.getPatientUser(patientId);
    const body = {
      elationId: elationId,
    };

    const url = environment.niceServiceUrl.concat(`/v1/users/${patient.userId}/elation`);
    return await this.httpClient.patch(url, body).toPromise();
  }

  public async syncPatientProfileAndAppointmentHistory(patientId: number): Promise<PatientElationSyncResponse> {
    const patient = await this.userService.getPatientUser(patientId);
    const body = {
      userId: patient.userId,
    };
    const url = environment.niceServiceUrl.concat('/v1/elation/force-sync-patient-appointments');
    const response = await this.httpClient.post<PatientElationSyncResponse>(url, body).toPromise();
    return {
      elationId: response.elationId,
      link: response.link,
    };
  }
}

interface PatientElationSyncResponse {
  elationId: string;
  link: string;
}

export interface PatientElationLinkResponse {
  isElationPatient: boolean;
  link: string;
}
